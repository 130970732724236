@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@font-face {
    font-family:'Big Caslon';
    src:url('../fonts/BigCaslon-Medium.eot');
    src:url('../fonts/BigCaslon-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BigCaslon-Medium.woff2') format('woff2'),
        url('../fonts/BigCaslon-Medium.woff') format('woff'),
        url('../fonts/BigCaslon-Medium.ttf') format('truetype'),
        url('../fonts/BigCaslon-Medium.svg#BigCaslon-Medium') format('svg');
    font-weight:500;
    font-style:normal;
    font-display:swap;
}
input[type="email"]::placeholder { 
                  
                /* Firefox, Chrome, Opera */
                text-align: center;
            }
            input[type="text"]::placeholder { 
                  
                /* Firefox, Chrome, Opera */
                text-align: center;
            }
            input[type="tel"]::placeholder { 
                  
                /* Firefox, Chrome, Opera */
                text-align: center;
            }
            input[type="email"]:-ms-input-placeholder {
                  
                /* Internet Explorer 10-11 */
                text-align: center;
            }
            input[type="email"]::-ms-input-placeholder { 
                  
                /* Microsoft Edge */
                text-align: center;}
            input[type="text"]::-ms-input-placeholder{ 
                  
                /* Firefox, Chrome, Opera */
                text-align: center;
            }
            input[type="tel"]::-ms-input-placeholder { 
                  
                /* Firefox, Chrome, Opera */
                text-align: center;
            }
html{font-size:62.5%;
    /* height: 100vh; */
    /* overflow: hidden;; */
}
body{font-family:'Open Sans', sans-serif;font-weight:300;font-style:normal;box-sizing:border-box;position:relative;font-size:1.4rem;line-height:24px;color:#ffffff;background: #1b1b1b;}
a, a:hover, a:focus, a:active{text-decoration:none;outline:0;}
input, input:focus, input:hover, input:active{outline:0;box-shadow:none;}
ul{margin:0 auto;padding:0;list-style:none;}
button, button:hover, button:active, button:focus{box-shadow:none;outline:0;border:0;}
.scrollupWrap{display:none;}
.map_wrapper #map{width:100%;height:100vh;overflow:hidden;}
.map_wrapper{position:relative;width:100%;height:100vh;overflow:hidden;}
.leftpanelWrap{max-width:475px;width:60%;background-color:#000;backdrop-filter:blur(10px);position:absolute;top:0;left:0;padding:8px 30px 15px;height:100%;    z-index: 9;
    opacity: 0.98;}
.formSection{display:flex;flex-flow:column;justify-content:space-between;}
.logoWrap {
    text-align: center;
    padding: 20px;
}
.logoWrap a{letter-spacing:0.02em;color:#FFFFFF;display:inline-block;line-height:18px;    font-size: 2.4rem;
    font-family: 'Big Caslon';}
.leftpanelWrap .centerSection{max-width:80%;width:100%;margin:0 auto;}
.leftpanelWrap .centerSection .titleWrap h1{font-family:'Big Caslon';font-size:3.8rem;color:#F2F2F2;}
.leftpanelWrap .centerSection .titleWrap h2{color: #fff;
    font-family: 'Big Caslon';
    font-size: 4rem;
    margin: 10px 0;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.02em;}
.leftpanelWrap .centerSection .titleWrap h4{color:#F2F2F2;font-family:'ABeeZee', sans-serif;font-weight:normal;margin:15px 0 0;font-size:1.4rem;}
.leftpanelWrap .centerSection .titleWrap p, .leftpanelWrap .centerSection .formWrap p, 
.leftpanelWrap .centerSection .shareCode p{
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 125.6%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #8B8B8B;}
    .formWrap {
        padding: 15px 0 0;
        width: 85%;
        margin: auto;
        margin-bottom: 40px;
    }
.formWrap ul li{margin:0 0 8px;}
.formWrap ul li .formField label{letter-spacing:0.05em;color:#F2F2F2;font-size:1.1rem;margin:0;display:block;}
.formWrap ul li .formField label span{color:#fb3333;}
input.form-control.search.react-mapbox-ac-input {
    padding: 0px 10px 0px 35px;
    text-indent: -10%;
}
span.location-icon {
    position: relative;
    bottom: 33px;
    left: 15px;
    font-size: 18px;
}
.formWrap ul li .formField input{border: 1px solid #575757;
    -webkit-appearance:none;
    -moz-appearance: none; 
    background-color: transparent;
    box-shadow: none;
    outline: 0;
    width: 100%;
    height: 45px;
    color: #F2F2F2;
    font-size: 1.3rem;
    padding: 0 10px;
    letter-spacing: 0.5px;
    font-weight: 600;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align-last:center;
    align-items:center;
    text-align: center;}

    .formField select{border: 1px solid #575757;
        -webkit-appearance:none;
        -moz-appearance: none; 
        background-color: transparent;
        box-shadow: none;
        outline: 0;
        width: 100%;
        height: 45px;
        color: #F2F2F2;
        font-size: 1.3rem;
        padding: 0 10px;
        letter-spacing: 0.5px;
        font-weight: 600;
        border-radius: 5px;
        margin-bottom: 10px;
        text-indent: 42%;}
.formWrap ul li .formField .selectWrap{border:1px solid #575757;position:relative;border-radius: 5px;
    margin-bottom: 15px;}
/* .formWrap ul li .formField .selectWrap:before{content:"";width:1px;background-color:#F2F2F2;height:100%;position:absolute;right:32px;top:0;} */
/* .formWrap ul li .formField .selectWrap:after{content:"\f11a";font-family:"Flaticon";position:absolute;right:10px;top:52%;transform:translateY(-50%);font-size:1.2rem;pointer-events:none;} */
.formWrap ul li .formField .selectWrap select{background-color:transparent;box-shadow:none;outline:0;width:100%;height:28px;border:0;appearance:none;-webkit-appearance:none;    color: #f2f2f2;
    padding: 0px 5px;}
.formWrap ul li.otpWrap input{background-color:#454342;border-radius:5px;border:1px solid #454342;width:120px;letter-spacing: 0.3em;}
.form-group{display:block;}
.form-group input{padding:0;height:initial;width:initial;margin-bottom:0;display:none;cursor:pointer;}
.form-group label{position:relative;cursor:pointer;}
.form-group label:before{content:'';-webkit-appearance:none;background-color:transparent;border:1px solid #F2F2F2;box-shadow:none;padding:5px;display:inline-block;position:relative;vertical-align:middle;cursor:pointer;margin-right:7px;}
.form-group input:checked + label:after{content:'';display:block;position:absolute;top: 8px;
    left: 4px;
    width: 4px;
    height: 8px;border:solid #F2F2F2;border-width:0 2px 2px 0;transform:rotate(45deg);}
.formWrap ul li button.btn{text-align:center;letter-spacing:0.05em;color:#0D0D0D;text-transform:uppercase;background-color:#F2F2F2;border-radius:5px;padding:6px 20px 5px;font-size:1.2rem;font-weight:600;box-shadow:none;outline:0;
border:0;    width: 100%; height: 46px;}
.shareCode{    padding: 20px 0 0;
    width: 90%;
    margin: auto;
    margin-bottom: 70%;}
.shareCode p{letter-spacing:0.4px;color:#F2F2F2;margin:0 0 5px;font-size:1.3rem;font-weight:500;}
.shareCode .inputField{background: #FFFFFF;
    border-radius: 5px;
    width: auto;
    padding: 5px 5px 5px 8px;
    position: relative;
    height: 45px;}
.shareCode .inputField input{border: none;
    box-shadow: none;
    background-color: transparent;
    letter-spacing: 0.4em;
    color: #262526;
    width: 100%;
    padding: 0 22px 0 0;
    height: 36px;
    text-align: center;
    font-weight: 600;}
.shareCode .inputField img{position:absolute;color:#767676;top:50%;transform:translateY(-50%);right:8px;font-size:1.4rem;    width:12px;opacity:0.8;}
.shareVia{padding:20px 0 0;}
.shareVia p{letter-spacing:0.4px;color:#F2F2F2;font-size:1.3rem;font-weight:300;}
.shareVia ul{display:flex;align-items:center;flex-flow:row wrap;justify-content:flex-start;}
.shareVia ul li{text-align:center;margin:0 20px 0 0;}
.shareVia ul li a .imgWrap{background-color:#2E2E2E;width:54px;height:54px;text-align:center;margin:0 auto;line-height:54px;border-radius:100%;}
.shareVia ul li a .imgWrap img{width:30px;}
.shareVia ul li a h3{color:#F2F2F2;letter-spacing:0.4px;font-weight:500;font-size:1.1rem;margin:5px 0 0;}
.contactUs{padding:20px 0 0;}
.contactUs p{letter-spacing:0.4px;color:#F2F2F2;font-size:1.2rem;font-weight:300;margin:0 0 2px;opacity:0.6;}
.contactUs ul li a{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.contactUs ul li a .icoWrap span{color:#F2F2F2;font-size:1.6rem;margin:0 8px 0 0;}
.contactUs ul li a .dataWrap p{letter-spacing:0.4px;color:#F2F2F2;font-size:1.2rem;font-weight:300;margin:0;opacity:0.6;}

.infowindowWrap{margin:0 auto;padding:0;display:inline-block;}
.infowindowWrap .imgWrap{border:1px solid #fff;border-radius:5px;width:152px;height:162px;position:relative;opacity: 1;}
/* .infowindowWrap .imgWrap:after{content:""; width:0; height:0;border-left:10px solid transparent;border-right:10px solid transparent;border-top:16px solid #f2f2f2;position:absolute;bottom:-20px;left:15px;} */
.infowindowWrap .imgWrap img{width:100%;height:100%;object-fit:cover;}
.infowindowWrap .dataWrap{text-align:left;position:absolute;bottom:0px;padding:0 8px 8px;left:0;}
.infowindowWrap .dataWrap h2{color:#F2F2F2;text-align:left;font-size:1.6rem;font-weight:600;letter-spacing:0.4px;text-transform:capitalize;margin:0;line-height:18px;}
.infowindowWrap .dataWrap p{color:#F2F2F2;text-align:left;margin:0;font-size:1.3rem;font-weight:300;line-height:16px;}
.infowindowWrap .dataWrap h3{color:#F2F2F2;font-size:1rem;text-align:left;font-weight:600;position:relative;padding:0 0 0 10px;margin:0;}
.infowindowWrap .dataWrap h3:before{content:"";background-image:url(../images/map-pin.png);background-repeat:no-repeat;background-size:100%;width:8px;height:8px;position:absolute;left:0px;top:2px;}
.infowindowWrap .dataWrap h4{color:#F2F2F2;background:rgba(242, 242, 242, 0.39);border-radius:10px;font-size:1rem;
line-height:12px;padding:3px 10px 5px;text-align:left;display:inline-block;margin:0;}

.mapboxgl-marker {
    z-index: 1 !important;
}

.marker-img.infowindowWrap .imgWrap:after {
    display: none;
}

.marker-img.infowindowWrap .imgWrap {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #FFFFFF;
}

.marker-img.infowindowWrap .imgWrap img {
    border-radius: 50px;
}

.mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-left{
    display: none !important;
}

.special-label {
    display: none;
}

.react-mapbox-ac-input {
    margin-bottom: 0px !important;
    border: 0px !important;
}

.react-mapbox-ac-menu {
    background-color: rgba(24, 24, 24, 1) !important;
    margin-top: 0.1rem !important;
}

.react-mapbox-ac-menu {
    height: 200px;
    overflow: auto;
}

.react-mapbox-ac-input{
    border-radius: 5px !important;
}

.mapboxgl-popup-content{
    padding: 0px !important;
    bottom: 55px;
}

.mapboxgl-popup-tip {
    position: relative;
    bottom: 55px;
}

.react-mapbox-ac-menu::-webkit-scrollbar, ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .react-mapbox-ac-menu::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .react-mapbox-ac-menu::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .react-mapbox-ac-menu::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-transform: uppercase;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #C8C8C8 !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    text-transform: uppercase;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #C8C8C8 !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    text-transform: uppercase;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #C8C8C8 !important;
  }
  :-moz-placeholder { /* Firefox 18- */
    text-transform: uppercase;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #C8C8C8 !important;
  }

.socialShare{border-top:1px solid #A6A6A6;padding:7px 0 0;margin:10px 0 0;}
.socialShare ul{display:flex;flex-flow:row wrap;justify-content:space-between;}
.socialShare ul li{position:relative;}
.socialShare ul li:after{content:"";width:25px;height:1px;background-color:#A6A6A6;position:absolute;right:-32px;top:50%;transform:translateY(-50%);}
.socialShare ul li:last-child:after{content:none;}
.socialShare ul li a{color:#F2F2F2;font-size:1.2rem;letter-spacing:0.4px;font-weight:400;}

.socialicon {
    padding: 7px 0 0;
    margin: 10px 0 0;
    width: 125px;
    position: absolute;
    top: 0px;
    right: 0px;
}
.socialicon ul{display:inline-flex;flex-flow:row wrap;justify-content:space-between;}
.socialicon ul li{position:relative;padding: 0px 5px;}
.socialicon ul li a{color:#F2F2F2;font-size:2.2rem;letter-spacing:0.4px;font-weight:400;}

.copyright{position: center;}
.copyright p,a{letter-spacing:0.035em;color:#767676;margin:0;font-size:1.2rem;line-height:12px;}

.selectWrap i {
    position: absolute;
}
.icon {
    padding: 20px;
    min-width: 40px;
}
  

/*New css start*/
.topRightBox{position:absolute;background-color:#ffffff;border-radius:10px;top:15px;right:15px;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:10px;}
.topRightBox .imgWrap{width:30px;height:30px;border-radius:100%;position:relative;float: left;}
.topRightBox .imgWrap>img{width:100%;height:100%;object-fit:cover;border-radius:100%;}
.topRightBox .imgWrap .flagImg{position:absolute;top:-8px;right:-8px;}
.topRightBox .dataWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:0 0 0 20px;}
.topRightBox .dataWrap .txtWrap{padding:0 15px 0 0;}
.topRightBox .dataWrap .txtWrap h2{font-weight:bold;font-size:1rem;line-height:12px;color:#191919;margin:0;}
.topRightBox .dataWrap .txtWrap p{font-weight:300;margin:0;color:#9e9e9e;margin:0;font-size:1rem;line-height:12px;}
.topRightBox .dataWrap .ctaWrap .btn{padding:0;border:0;background-color:transparent;outline:0;border-radius:0;box-shadow:none;color:#ACACAC;font-size:1rem;}
.bottomRightBox{position:absolute;right:15px;bottom:15px;}
.bottomRightBox .btn{padding:0;border:0;background-color:transparent;outline:0;border-radius:0;box-shadow:none;}
.bottomRightBox .imgWrap{position:relative;}
.bottomRightBox .fa-play-circle{color:#ffffff;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);font-size:5rem;}
.videoModal .modal-dialog{display:flex;align-items:center;justify-content:center;height:100%;padding:0;margin:0 auto;}
.modal-open .modal.videoModal{background-color:rgba(0,0,0,0.7);}
.videoModal .modal-content{border:0;border-radius:0;background-color:transparent;text-align:center;}
.videoModal .videoWrap{position:relative;padding:20px 0;}
.videoModal .videoWrap video{width:100%;border-radius:10px;}
.videoModal .videoWrap .btn{background-color:transparent;box-shadow:none;outline:0;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);font-size:3rem;border:3px solid #ffffff;border-radius:100%;padding:0;width:80px;height:80px;text-align:center;display:flex;align-items:center;justify-content:center;color:#ffffff;z-index:9;}
.videoModal .modal-content p{font-family:'Big Caslon';font-size:1.8rem;}
.videoModal .modal-content h2{font-family:'Big Caslon';font-size:3.4rem;letter-spacing:0.6px;margin:0;}
.videoModal .modal-content .btnWishList{color:#262526;border-radius:5px;border:0;box-shadow:none;outline:0;background-color:#ffffff;text-transform:uppercase;padding:10px 10px;width:170px;font-weight:700;font-size:1rem;letter-spacing:0.6px;cursor: pointer;}
.accompanyWrap{background-color:#1b1b1b;padding:60px 0;}
.accompanyWrap .titleWrap{text-align:center;}
.accompanyWrap .titleWrap h2{font-family:'Big Caslon';font-size:3.4rem;letter-spacing:0.6px;}
.accompanyWrap .chartWrap{margin:0 auto;text-align:left;    position: relative;}

.rioWrap {
    position: absolute;
    top: 75px;
    right: 0px;
}
.rioWrap{display:flex;align-items:center;justify-content:center;flex-flow:column;    padding-top: 50px;}
.rioWrap .chartTooltipWrap{display:inline-block;text-align:center;border:2px solid #FFFFFF;border-radius:10px;padding:10px;position:relative;}
.rioWrap .chartTooltipWrap:after{content:"\f11a";font-family:Flaticon;position:absolute;bottom:-16px;background-color:#1b1b1b;text-align:center;margin:0 auto;left:50%;transform:translateX(-50%);}
.rioWrap .chartTooltipWrap h2{font-size:13px;font-weight:bold;margin:0;}
.rioWrap .chartTooltipWrap p{margin:0;color:#777777;font-weight:600;}
.rioWrap .shapesWrap{margin:0 auto;display:block;text-align:center;padding:60px 0 0;position:relative;}
.rioWrap .shapesWrap:before{content:"";width:2px;background-color:#ffffff;height:30px;position:absolute;bottom:22px;left:50%;transform:translateX(-50%);}
.rioWrap .shapesWrap span{width:16px;height:16px;border:3px solid #FFFFFF;border-radius:100%;background-color:transparent;display:inline-block;margin:0 -4px;} 

.apexcharts-legend-text {
    color: #fff !important;
}

.apexcharts-toolbar {
    display: none !important;
}

.accompanyWrap .chartWrap.leftchart .mixed-chart {
    width: 70%;
    float: left;
}

.closemodal {
    font-size: 30px;
    position: absolute;
    right: 0px;
    color: #fff;
    cursor: pointer;
}

.apexcharts-tooltip {
    display: none !important;
}

text.highcharts-credits {
    display: none;
}

.scrollupWrap {
    display: none;
}


.map_wrapper .mapimg{width:100%;height:100vh;overflow:hidden;background-image:url(../images/map-img.png);}
.userdetailpage.infoDtlPge{position:relative;}
.userdetailpage .infoDtlWrap{position:absolute;background-color:#000000;width:100%;bottom:0;left:0;padding:0 0 40px;z-index:99;}
.userdetailpage .userInfoImg{width:100px;height:100px;border-radius:100%;border:2px solid #ffffff;overflow:hidden;position:absolute;left:0;right:0;margin:0 auto;top:-62px;}
.userdetailpage .userInfoName{padding:60px 0 0;text-align:center;}
.userdetailpage .userInfoName h2{ font-family:'Big Caslon';color:#ffffff;font-size:2.8rem;margin:0 0 2px;letter-spacing:1px;}
.userdetailpage .userInfoName h2 img{margin:-20px 0 0;}
.userdetailpage .userInfoName h4{text-transform:uppercase;color:#ffffff;margin:0 0 2px;font-weight:400;font-size:1.3rem;letter-spacing:0.4px;}
.userdetailpage .userInfoName p{color:#ACACAC;margin:0;font-weight:500;}
.userdetailpage .infoMdlDtl{padding:40px 0;}
.userdetailpage .infoMdlDtl .commonInfodtl{border-top:2px solid #636363;border-bottom:1px solid #636363;padding:20px 0;display:flex;justify-content:space-between;flex-flow:row wrap;height:100%;}
.userdetailpage .infoMdlDtl .commonInfodtl .leftData h2{font-size:1.8rem;font-weight:500;}
.userdetailpage .infoMdlDtl .leftPanel .leftData{width:40%;}
.userdetailpage .infoMdlDtl .leftPanel .rightData{width:60%;}
.userdetailpage .infoMdlDtl .leftPanel .rightData ul{display:flex;flex-flow:row wrap;justify-content:flex-end;}
.userdetailpage .infoMdlDtl .leftPanel .rightData ul li{margin:0 2px 7px;}
.userdetailpage .infoMdlDtl .leftPanel .rightData ul li a{text-transform:uppercase;color:#ffffff;font-weight:500;font-size:1.2rem;letter-spacing:0.6px;padding:4px 6px;border:1px solid #ffffff;border-radius:5px;}
.userdetailpage .infoMdlDtl .leftPanel .rightData p{color:#ACACAC;text-align:right;margin:0;}
.userdetailpage .infoMdlDtl .leftPanel .rightData p a{color:#ffffff;font-weight:500;}
.userdetailpage .infoMdlDtl .rightPanel .leftData{width:50%;display:flex;flex-flow:column wrap;justify-content:space-between;}
.userdetailpage .infoMdlDtl .rightPanel .leftData p{color:#ACACAC;font-size:1.2rem;line-height:18px;font-weight:500;margin:0;}
.userdetailpage .infoMdlDtl .rightPanel .leftData p span{color:#ffffff;}
.userdetailpage .infoMdlDtl .rightPanel .rightData{width:50%;text-align:right;}
.userdetailpage .infoBtmDtl{background-color:#262626;border-radius:10px;display:flex;flex-flow:row wrap;justify-content:space-between;padding:30px; align-items:center;}
.userdetailpage .infoBtmDtl .leftDataWrap{display:flex;flex-flow:row wrap;width:calc(100% - 280px);align-items:center;}
.userdetailpage .infoBtmDtl .leftDataWrap .imgWrap{width:60px;margin-right:15px;}
.userdetailpage .infoBtmDtl .leftDataWrap .dataWrap{width:calc(100% - 75px);}
.userdetailpage .infoBtmDtl .leftDataWrap .dataWrap p{margin:0;color:#ACACAC;font-weight:500;line-height:20px;}
.userdetailpage .infoBtmDtl .leftDataWrap .dataWrap p span{color:#ffffff;}
.userdetailpage .infoBtmDtl .rightDataWrap .ctaWrap a{display:inline-block;background-color:#ffffff;border-radius:10px;color:#262526;font-weight:700;font-size:1.2rem;padding:12px 28px 10px;text-align:center;}


.collab .mapImgwrapper{background-image:url(../images/map-img.png);background-repeat:no-repeat;background-size:cover;padding:20px 0 50px;}
.collab .mapImgwrapper .logoWrap {text-align: left;}
.collab.infoDtlPge{position:relative;}
.userdetailpage .collab .infoDtlWrap{background-color:#000000;width:100%;padding:0 0 40px;z-index:99;}
.collab .userInfoImg{width:100px;height:100px;border-radius:100%;border:2px solid #ffffff;overflow:hidden;margin:20px auto 0;}
.collab .userInfoName{padding:30px 0 0;text-align:center;}
.collab .userInfoName h2{ font-family:'Big Caslon';color:#ffffff;font-size:2.8rem;margin:0 0 2px;letter-spacing:1px;}
.collab .userInfoName p{color:#ACACAC;margin:10px auto 0;font-weight:500;font-size:1.3rem;line-height:18px;}
.collab .userInfoName p a{color:#ffffff;text-decoration:underline;font-weight:600;}
.collab .infoMdlDtl{padding:30px 0;}
.collab .infoMdlDtl .palyVideoWrap{margin:0 auto;text-align:left;}
.collab .infoMdlDtl .palyVideoWrap .imgWrap{position:relative;display:inline-block;}
.collab .infoMdlDtl .palyVideoWrap .imgWrap img{max-width: 100px; max-height: 100px;border: 1px solid #fff;border-radius: 15px;}
.collab .infoMdlDtl .videoLink{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:9;cursor:pointer;}
.collab .infoMdlDtl .palyVideoWrap .dataWrap{max-width:100px;text-align:left;margin:10px 0 0;}
.collab .infoMdlDtl .palyVideoWrap .dataWrap p{color:#ffffff;margin:0 auto;font-size:1.2rem;line-height:16px;font-weight:600;}
.collab .infoMdlDtl .palyVideoWrap .dataWrap p span{color:#8B8B8B;font-weight:400;} 
.collab .infoMdlDtl .audioListing li{border-top:1px solid #3C3C3C;padding:15px 0;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.collab .infoMdlDtl .audioListing li:last-child{padding:15px 0 0;}
.collab .infoMdlDtl .audioListing li .leftPanel{width:calc(100% - 120px);display:flex;flex-flow:row wrap;align-items:center;}
.collab .infoMdlDtl .audioListing li .leftPanel a{display:flex;flex-flow:row wrap;justify-content:space-between;}
.collab .infoMdlDtl .audioListing li .dataWrap{padding-left:10px;}
.collab .infoMdlDtl .audioListing li .dataWrap h2{font-size:1.4rem;font-weight:600;margin:0;color:#cccccc;}
.collab .infoMdlDtl .audioListing li.active .dataWrap h2{color:#ffffff;}
.collab .infoMdlDtl .audioListing li .dataWrap p{color:#ACACAC;font-size:1.2rem;font-weight:500;margin:0;line-height:18px;}
.collab .infoMdlDtl .audioListing li .rightPanel .btnRemove{border:1px solid #ffffff;box-shadow:none;outline:0;background-color:transparent;color:#ffffff;text-transform:uppercase;font-weight:600;letter-spacing:0.6px;border-radius:5px;padding:6px 15px;font-size:1.1rem;display:none; transition:0.3s all linear;}
.collab .infoMdlDtl .audioListing li.active .rightPanel .btnRemove{display:inline-block;}
.collab .infoMdlDtl .audioListing li:hover .rightPanel .btnRemove{display:inline-block;}
.collab .footerWrap{background-color:#262626;display:flex;flex-flow:row wrap;justify-content:space-between;padding:20px 0;align-items:center;}
.collab .infoBtmDtl{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;} 
.collab .infoBtmDtl .leftDataWrap{display:flex;flex-flow:row wrap;width:calc(100% - 65%);align-items:center;}
.collab .infoBtmDtl .leftDataWrap .imgWrap{width:60px;margin-right:15px;}
.collab .infoBtmDtl .leftDataWrap .dataWrap{width:calc(100% - 75px);}
.collab .infoBtmDtl .leftDataWrap .dataWrap p{margin:0;color:#ACACAC;font-weight:500;line-height:20px;}
.collab .infoBtmDtl .leftDataWrap .dataWrap p span{color:#ffffff;}
.collab .infoBtmDtl .rightDataWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.collab .infoBtmDtl .rightDataWrap .inviteCodeWrap{display:flex;flex-flow:row wrap;align-items:center;position:relative;background-color:#4B4B4B;border-radius:10px;padding:10px 20px;margin:0 15px 0 0;}
.collab .infoBtmDtl .rightDataWrap .inviteCodeWrap p{margin:0 10px 0 0;color:#FFFFFF;font-weight:500;font-size:1.2rem;letter-spacing:0.6px;}
.collab .infoBtmDtl .rightDataWrap .inviteCodeWrap h2{margin:0;color:#ACACAC}
.collab .infoBtmDtl .rightDataWrap .inviteCodeWrap:after{content:"";background-image:url(../images/icoArrow.png);background-repeat:no-repeat;  width:16px;height:18px;position:absolute;right:-13px;top:50%;transform:translateY(-50%);}
.collab .infoBtmDtl .rightDataWrap .ctaWrap .btn{display:inline-block;background-color:#ffffff;border-radius:10px;color:#262526;font-weight:700;font-size:1.2rem;text-align:center;border:0;box-shadow:none;height:40px;padding:5px 20px;margin:0 0 0 6px;}
.collab .infoBtmDtl .rightDataWrap .ctaWrap .btn img{margin-right:6px;}

@media(max-width:991px){
    .infoDtlWrap .container{max-width:100%;width:100%;}
    .map_wrapper{height:auto;}
    .infoBtmDtl .leftDataWrap .imgWrap{width:40px;margin-right:10px;}
    .infoBtmDtl .leftDataWrap .dataWrap p{font-size:1.2rem;}
    .collab .infoBtmDtl .rightDataWrap .ctaWrap .btn{height:36px;padding:5px 10px;}
    .infoBtmDtl .rightDataWrap .inviteCodeWrap{padding:10px;}
}

@media(max-width:767px){
    .infoBtmDtl{flex-direction:column;}
    .collab .infoBtmDtl .leftDataWrap{width:100%;margin:0 0 20px;}
    .collab .infoBtmDtl .rightDataWrap{width:100%;}
    .collab .infoBtmDtl .rightDataWrap .ctaWrap {width: 100%;}
    .collab .infoBtmDtl .rightDataWrap .ctaWrap .btn{width: 48%;}
}

@media(max-width:575px){
    .userdetailpage .infoBtmDtl .leftDataWrap{width:100%;margin:0 0 15px;}
    .infoMdlDtl .palyVideoWrap{margin:0 auto 20px;text-align:center;}
    .infoMdlDtl .palyVideoWrap .dataWrap{text-align:center;max-width:100%;}
}

@media(max-width:500px){
    .collab .infoBtmDtl .rightDataWrap .inviteCodeWrap{margin:0 0 15px;}
}

@media(max-width:340px){
    .map_wrapper #map{height:160vh;}
    .infoMdlDtl .audioListing li .leftPanel,
    .infoMdlDtl .audioListing li .leftPanel a{flex-flow:column wrap;justify-content:center;align-items:flex-start;}
    .infoMdlDtl .audioListing li .dataWrap{padding-left:0;margin:10px 0 0;}
}