@media(max-width:1440px){
    
}
@media(max-width:1365px){
    
}
@media(max-width:1280px){
   
}
@media(max-width:1199px){
   
}
@media(max-width:1100px){
    html{font-size:58.5%;}
    
}
@media(max-width:1023px){
   
}

@media(max-width:991px){
   
    html{font-size:56.5%;}
    .scrollupWrap{display:block;text-align:center;margin:0 auto;}
    .scrollupWrap i{color:#F2F2F2;}
    .scrollupWrap p{margin:0;color:#F2F2F2;line-height:12px;font-size:1.2rem;font-weight:600;letter-spacing:0.5px;}
    /* .formSection{width:100%;margin:0;padding:0;overflow:auto;transition:all ease-in-out 0.3s;-webkit-transition:all ease-in-out 0.3s;max-width:100%;border-radius:10px 10px 0 0;-webkit-border-radius:10px 10px 0 0;padding:15px 30px;height: auto;} */
    .formSection{position: fixed;
        width: 100%;
        height: 50%;
        top: 50%;
        left: 0;
        margin: 0;
        padding: 0;
        overflow: auto;
        transition: all ease-in-out 0.3s;
        -webkit-transition: all ease-in-out 0.3s;
        max-width: 100%;
        border-radius: 10px 10px 0 0;
        -webkit-border-radius: 10px 10px 0 0;
        padding: 15px 30px;}
    /* .formSection.active{height:100%;top:0;bottom:auto;} */
    .formSection.active{position: absolute;width:100%;margin:0;padding:0;overflow:auto;transition:all ease-in-out 0.3s;-webkit-transition:all ease-in-out 0.3s;max-width:100%;border-radius:10px 10px 0 0;-webkit-border-radius:10px 10px 0 0;padding:15px 30px;height: auto;top:0;bottom:auto;}

    .socialicon ul li a {
            font-size: 2.5rem;
    }

    .socialicon {
        position: relative;
        left: 35%;
        padding: 5px 0 0;
        margin: 5px 0 0;
    }
    
        .mob-logo.logoWrap {
            background: transparent !important;
            position: absolute;
            top: 0px;
            width: 100%;
            z-index: 2;
            padding: 15px 0px 0px;
        }
        
        .mob-logo.logoWrap a {
            color: #fff;
            font-size: 3.4rem;
            z-index: 999999999999;
        }

        canvas.mapboxgl-canvas {
            width: 100% !important;
            height: 100vh !important;
        }

        .videoModal {
            width: 100%;
            padding: 5%;
            background: rgba(0, 0, 0, 0.9) !important;
        }
        /* .leftpanelWrap.formSection {
            opacity: 0.8;
        } */
}

@media(max-width:650px){
    .collab .userInfoName{padding:30px 0 0;text-align:left;}

    .leftpanelWrap .centerSection{max-width:100%;}
    .apexcharts-canvas {
        width: 100% !important;
    }

    .mixed-chart {
        width: 100% !important;
        height: auto;
    }

    .apexcharts-svg {
        width: 100% !important;
    }

    .rioWrap {
        display: flex;
        align-items: flex-end;
        flex-flow: column;
        padding-top: 50px;
    }

    .rioWrap .chartTooltipWrap:after {
        left: 50%;
    }

    .rioWrap .chartTooltipWrap h2 {
        font-size: 12px;
    }

    .rioWrap .chartTooltipWrap {
        width: 155px;
    }



    .rioWrap .shapesWrap {
        padding: 35px 0 0;
    }

    .rioWrap .shapesWrap:before {
        bottom: 25px;
    }

    .rioWrap .shapesWrap span {
        margin: 0 -3px;
    }

    .map_wrapper {
        height: auto;
        overflow: initial;
    }

    

    .topRightBox {
        position: relative;
        top: 0px;
        right: 0px;
        z-index: 99;
        width: 240px;
        margin: auto;
    }

    .bottomRightBox{
        display: none;
    }

    .accompanyWrap {
        padding: 120px 0;
    }

}
@media(max-width:575px){
}
@media(max-width:459px){

}
@media(max-width:340px){ 
}