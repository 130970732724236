@import './common/css/bootstrap.min.css';
@import './common/css/flaticon.css';
@import './common/css/fontawesome-all.min.css';
@import './common/css/style.css';
@import './common/css/device.css';

.highcharts-yaxis-grid .highcharts-grid-line {
	stroke-width: 0px;
	stroke: transparent;
}

.search {
    margin-bottom: 1.5rem;
  }
  
  .react-mapbox-ac-menu {
    width: auto;
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid #ccc;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: -1.3rem;
  }
  
  .react-mapbox-ac-input {
    margin-bottom: 1.5rem;
  }
  
  .react-mapbox-ac-suggestion {
    font-size: 18px;
    margin-bottom: .5rem;
    cursor: pointer;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .react-mapbox-ac-suggestion:hover {
    background-color: #58a;
  }